const filters = {
	localizeText: function (defaultText, localizeString, vm) {
		if (!localizeString && !defaultText)
			return 'Invalid text string setting';

		if (!localizeString) return defaultText;

		var localizedText = vm.$t(localizeString);

		if (localizedText !== localizeString) return localizedText;

		return defaultText;
	}
};
export default filters;
