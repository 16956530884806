/**
 * Format a string with html display elements.
 * @param {string} textString The text we are goint to format.
 * @param {object} formatingObject The object with the details of how to format the string.
 */
export const createHtmlFormatedText = function (textString, formatingObject) {
	if (formatingObject?.Bold === true) {
		textString = `<b>${textString}</b>`;
	}

	if (formatingObject?.Italic === true) {
		textString = `<i>${textString}</i>`;
	}

	if (formatingObject?.Underline === true) {
		textString = `<u>${textString}</u>`;
	}

	// ok this is a bit fiddely as we have to build up the span style setting
	if (formatingObject?.Colour && formatingObject?.FontSize) {
		var textColourValue = formatingObject.Colour;
		var fontSizeValue = formatingObject.FontSize;
		textString = `<span style="color:${textColourValue}; font-size:${fontSizeValue}px;">${textString}</span>`;
		return textString;
	}

	if (formatingObject?.FontSize) {
		var fontSize = formatingObject.FontSize;
		textString = `<span style="font-size:${fontSize};">${textString}</span>`;
	}

	if (formatingObject?.Colour) {
		var textColour = formatingObject.Colour;
		textString = `<span style="color:${textColour};">${textString}</span>`;
	}

	return textString;
};
