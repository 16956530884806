<template>
	<v-dialog
		v-model="showDialog"
		fullscreen
		:scrim="false"
		transition="dialog-bottom-transition"
	>
		<v-card data-modalCard :loading="isLoading">
			<v-toolbar dark color="primary">
				<v-btn icon dark @click="onCloseTrigger()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-col class="text-center" cols="12">
				<information-components-list
					ref="modalInfoComponents"
					:user-prompts="viewSettings"
					@closeModel="closeModel"
				/>
			</v-col>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useScannerStore } from '@/stores/scannerFunctionStore';
import InformationComponentsList from '@/components/ScanningFunctions/InformationComponentList.vue';
import scannerFunctionMixin from '@/mixins/scannerFunctionMixin';
import { CallExternalApi } from '@/services/externalApiProcesser';
export default {
	name: 'ModelComponent',
	components: {
		'information-components-list': InformationComponentsList
	},
	mixins: [scannerFunctionMixin],
	props: {
		modelViewSetup: {
			type: Object,
			default: () => {}
		}
	},
	data: () => ({
		showDialog: false,
		isLoading: false,
		viewSettings: [],
		postLoadModalActions: {}
	}),
	computed: {
		...mapState(useScannerStore, ['getShowModalValue'])
	},
	watch: {
		getShowModalValue(newValue) {
			this.showDialog = newValue;
			// if we are opening then lets check for post open events
			if (newValue) {
				this.postProcessAction();
			}
		},
		modelViewSetup: function (newValue) {
			if (
				!newValue ||
				!newValue?.stepActions ||
				newValue.stepActions.length === 0
			) {
				this.viewSettings = [];
				return;
			}

			let actionViewSettings = newValue.stepActions.filter((x) => {
				return x.modalView;
			});

			let modelActual = actionViewSettings.map(function (a) {
				return a.modalView;
			});

			if (modelActual) {
				this.viewSettings = modelActual[0];
			} else {
				this.viewSettings = [];
			}

			if (newValue.stepActions[1]?.postLoadModalAction) {
				this.postLoadModalActions =
					newValue.stepActions[1].postLoadModalAction;
			}
		}
	},
	methods: {
		...mapActions(useScannerStore, ['updateModalState']),
		...mapState(useScannerStore, ['getLastApiResponse']),
		onCloseTrigger() {
			//we need to update the store to close the modal as well
			this.updateModalState(false);
		},
		postProcessAction() {
			if (this.postLoadModalActions.verb) {
				//do we need to look external or internal
				if (this.postLoadModalActions.externalEndpointId > 0) {
					this.postProcessActionExtenalEndpoint();
				}
			}
		},
		postProcessActionExtenalEndpoint() {
			this.isLoading = true;

			var overideEndpoint = this.createUrlReplacements();

			CallExternalApi(
				this.postLoadModalActions.externalEndpointId,
				{},
				{},
				overideEndpoint
			).then(() => {
				this.isLoading = false;
			});
		},
		createUrlReplacements() {
			var endpoint = this.postLoadModalActions.endPointPath;
			var apiResponse = this.getLastApiResponse();

			console.log('pre endpoint', endpoint);

			endpoint = this.swapTokenForValuesFromObject(
				endpoint,
				'',
				apiResponse
			);

			console.log('post endpoint', endpoint);

			return endpoint;
		},
		closeModel(value) {
			console.log('close emit', value);
			this.onCloseTrigger();
		}
	}
};
</script>
