<!-- eslint-disable vue/no-v-html -->
<template>
	<v-row no-gutters>
		<v-col cols="sm">
			<v-card
				class="pt-1 pl-2 labelDisplayBox"
				outlined
				tile
				color="background"
			>
				<span data-lableBox v-html="getLableText"> </span>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from 'pinia';
import { useScannerStore } from '@/stores/scannerFunctionStore';
import stringFormat from '@vue-formily/string-format';
import { createHtmlFormatedText } from '@/services/addTextFormatting';

export default {
	name: 'LabelDisplayBox',
	props: {
		inputBox: {
			type: Object,
			required: true
		}
	},
	computed: {
		getLableText() {
			//set the initial text based on the version from the config
			var textToReturn = this.inputBox.Text;
			var functionToPerform = this.inputBox.ValueFunction ?? '';

			//go and get all the tokens out of our string
			var tokensInLableString = this.getTokensFromString(
				this.inputBox.Text
			);

			var vm = this;
			//loop over each of our tokens
			tokensInLableString.forEach(function (propertyToFind) {
				//try and find the replacement text for our token
				var replaceTokenText = vm.getLableTextFromApiResponse(
					textToReturn,
					propertyToFind,
					functionToPerform
				);

				if (!replaceTokenText) {
					replaceTokenText = vm.getLableTextFromUserResponses(
						textToReturn,
						propertyToFind
					);
				}

				if (!replaceTokenText) {
					replaceTokenText = vm.getLableTextFromAllApiResponses(
						textToReturn,
						propertyToFind
					);
				}

				//if we have something, then format it, and replace in the orignal string
				if (replaceTokenText) {
					replaceTokenText = stringFormat.format(
						replaceTokenText.toString(),
						{ 0: propertyToFind }
					);
					var replaceToken = '#' + propertyToFind + '#';
					textToReturn = textToReturn.replaceAll(
						replaceToken,
						replaceTokenText
					);
				}
			});

			textToReturn = createHtmlFormatedText(textToReturn);

			//now lets handle the post lable text
			if (this.inputBox.PostLableText) {
				textToReturn = `${this.inputBox.PostLableText} ${textToReturn}`;
			}

			return textToReturn;
		}
	},
	methods: {
		...mapState(useScannerStore, [
			'getLastApiResponse',
			'getPreviousResponses',
			'getAllApiResponses'
		]),
		getTokensFromString(inputString) {
			const tokens = [...inputString.matchAll(/#([^#]+)#/g)].map(
				(match) => match[1]
			);

			return tokens;
		},

		getLableTextFromApiResponse(
			textToReturn,
			propertyToFind,
			functionToPerform
		) {
			var apiResponse = this.getLastApiResponse();

			if (functionToPerform) {
				switch (functionToPerform.toUpperCase()) {
					case 'SUM':
						//sum up all the property in the array
						return apiResponse
							.map((item) => item[propertyToFind])
							.reduce((prev, curr) => prev + curr, 0);
				}
			}

			if (apiResponse !== null) {
				if (!Array.isArray(apiResponse)) {
					//if we are an object then we need to extact the properties out
					if (typeof apiResponse === 'object') {
						return apiResponse[propertyToFind];
					} else {
						textToReturn = apiResponse;
					}
				} else {
					if (apiResponse[0]) {
						return apiResponse[0][propertyToFind];
					}
				}
			}

			return '';
		},
		// TODO can we remove these 2 calls and push to the generic one?
		getLableTextFromAllApiResponses(textToReturn, propertyToFind) {
			var allApiResponses = this.getAllApiResponses();
			textToReturn = '';

			if (!allApiResponses || allApiResponses.lengh === 0) {
				return textToReturn;
			}

			allApiResponses.forEach(function (item) {
				if (typeof item === 'object' && item !== null) {
					//we are an object, so lets see if we can find our key
					if (item[propertyToFind]) {
						textToReturn = item[propertyToFind];
					}
				}
			});

			return textToReturn;
		},

		getLableTextFromUserResponses(textToReturn, propertyToFind) {
			var allApiUserResponses = this.getPreviousResponses();
			textToReturn = '';

			allApiUserResponses.forEach((responseStep) => {
				//ok we need to nest here again, as we have an array of arrays for each step
				responseStep.forEach((response) => {
					if (response) {
						if (
							propertyToFind.toUpperCase() ===
							response.key.toUpperCase()
						) {
							textToReturn = response.value;
						}
					}
				});
			});

			return textToReturn;
		}
	}
};
</script>
