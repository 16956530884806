<template>
	<v-container>
		<v-row class="text-center">
			<v-col class="mb-4">
				<v-form ref="form" v-model="isFormValid">
					<v-text-field
						v-model="user.username"
						data-userName
						:label="
							translate('Username', 'comp_LoginScreen_Username')
						"
						:rules="nameRules"
						required
					/>

					<v-text-field
						v-model="user.password"
						data-password
						:label="
							translate('Password', 'comp_LoginScreen_Password')
						"
						:rules="passwordRules"
						type="password"
						required
					/>

					<v-select
						v-model="user.site"
						data-sites
						:items="siteItems"
						item-text="key"
						:label="translate('Site', 'comp_LoginScreen_Site')"
						:rules="siteRules"
						required
					/>
					<v-btn
						color="primary"
						elevation="2"
						:disabled="!isFormValid"
						:loading="isSubmitting"
						:title="translate('Login', 'comp_NavBar_LogIn')"
						@click="handleSubmit"
						v-text="translate('Login', 'comp_NavBar_LogIn')"
					/>
				</v-form>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/userStore';
import stringFormat from '@vue-formily/string-format';
import { loadLocaleFromApi } from '@/plugins/i18n';
import { mapState, mapActions } from 'pinia';
import { ApplyUserTheme, DoesThemeExist } from '@/services/createUserTheme';

export default {
	name: 'LoginComponent',
	data: () => ({
		user: {
			username: null,
			password: null,
			site: null
		},
		isSubmitting: false,
		siteItems: [],
		isFormValid: false
	}),
	computed: {
		nameRules() {
			let defaultText = 'Usename is required';
			let ruleMainText = this.translate(
				'Usename is required',
				'comon_error_Required'
			);
			let ruleToken1 = this.translate(
				'Username',
				'comp_LoginScreen_Username'
			);

			let ruleText =
				ruleMainText && ruleToken1
					? stringFormat.format(
							this.translate(
								'{0} is required',
								'comon_error_Required'
							)?.toString(),
							{
								0: this.translate(
									'Username',
									'comp_LoginScreen_Username'
								)?.toString()
							}
							// eslint-disable-next-line prettier/prettier
					  )
					: ruleMainText;

			return [(v) => !!v || ruleText || defaultText];
		},
		siteRules() {
			let defaultText = 'Site is required';
			let ruleMainText = this.translate(
				defaultText,
				'comon_error_Required'
			);
			let ruleToken1 = this.translate('Site', 'comp_LoginScreen_Site');

			let ruleText =
				ruleMainText && ruleToken1
					? stringFormat.format(
							this.translate(
								'{0} is required',
								'comon_error_Required'
							)?.toString(),
							{
								0: this.translate(
									'Site',
									'comp_LoginScreen_Site'
								)?.toString()
							}
						)
					: ruleMainText;

			return [(v) => !!v || ruleText || defaultText];
		},
		passwordRules() {
			let defaultText = 'Password is required';
			let ruleMainText = this.translate(
				'Password is required',
				'comon_error_Required'
			);
			let ruleToken1 = this.translate(
				'Password',
				'comp_LoginScreen_Password'
			);

			let ruleText =
				ruleMainText && ruleToken1
					? stringFormat.format(
							this.translate(
								'{0} is required',
								'comon_error_Required'
							)?.toString(),
							{
								0: this.translate(
									'Password',
									'comp_LoginScreen_Password'
								)?.toString()
							}
						)
					: ruleMainText;

			return [(v) => !!v || ruleText || defaultText];
		}
	},

	mounted() {
		this.loadFormData();
	},
	methods: {
		...mapActions(useAuthStore, ['login']),
		...mapActions(useUserStore, [
			'setUserProfile',
			'getUserSites',
			'fetchOperatorTheme'
		]),
		...mapState(useUserStore, ['getSites', 'getUserSetLanguage']),
		handleSubmit() {
			this.isSubmitting = true;

			this.login({
				username: this.user.username,
				password: this.user.password,
				site: this.user.site,
				clientId: process.env.VUE_APP_CLIENT_ID,
				clientSecret: process.env.VUE_APP_CLIENT_SECRET,
				grantType: process.env.VUE_APP_GRANT_TYPE
			})
				.then((res) => {
					this.setUserProfile({ data: res, site: this.user.site });
					var userSetLanguage = this.getUserSetLanguage;
					loadLocaleFromApi(userSetLanguage);
				})
				.then(() => {
					if (!DoesThemeExist('Light')) {
						return this.fetchOperatorTheme(
							this.user.username,
							'Light'
						);
					} else {
						return;
					}
				})
				.then(() => {
					if (!DoesThemeExist('Dark')) {
						return this.fetchOperatorTheme(
							this.user.username,
							'Dark'
						);
					} else {
						return;
					}
				})
				.catch(() => {
					this.resetForm();
				})
				.finally(() => {
					ApplyUserTheme(this);
					this.isSubmitting = false;
					this.$router.push({ path: '/' });
				});
		},
		loadFormData() {
			this.siteItems = this.getSites();
		},
		resetForm() {
			this.user.username = null;
			this.user.password = null;
			this.user.site = null;
		},
		translate(defaultText, localizeText) {
			return this.$options.filters.localizeText(
				defaultText,
				localizeText,
				this
			);
		}
	}
};
</script>
