<template>
	<v-container fluid>
		<v-card class="mx-auto" tile>
			<v-list width="100%" style="padding: 0">
				<v-list-item
					v-for="(item, i) in getListData"
					:key="i"
					style="border-bottom: 1px solid #ccc"
					three-line
					@click="rowClick(item)"
				>
					<v-list-item-content>
						<v-list-item-title>
							<!--eslint-disable-next-line-->
							<span v-html="item.title"> </span>
						</v-list-item-title>
						<v-list-item-subtitle class="text--primary">
							<!--eslint-disable-next-line-->
							<span v-html="item.subtitle"></span>
						</v-list-item-subtitle>
						<v-list-item-subtitle>
							<!--eslint-disable-next-line-->
							<span v-html="item.details"></span>
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card>
	</v-container>
</template>

<script setup>
import { defineProps, defineEmits, computed, getCurrentInstance } from 'vue';
import { useScannerStore } from '@/stores/scannerFunctionStore';
import { getNestedPropertyValue } from '@/services/getNestedValues';
import { createHtmlFormatedText } from '@/services/addTextFormatting';

const scannerStore = useScannerStore();
const vm = getCurrentInstance();
const $options = vm.proxy?.$options || undefined;
const emit = defineEmits(['listItemSelected']);

const props = defineProps({
	inputBox: {
		type: Object,
		required: true
	}
});

const getListData = computed(() => {
	var listData = [];

	var lastApiResponse = scannerStore.getLastApiResponse;

	if (
		lastApiResponse === null ||
		lastApiResponse.length === 0 ||
		!Array.isArray(lastApiResponse)
	) {
		return listData;
	}

	lastApiResponse.forEach(function (responseRow) {
		var line1Config = props.inputBox.Line1;
		var line2Config = props.inputBox.Line2;
		var line3Config = props.inputBox.Line3;

		var title = getLineData(line1Config, responseRow);
		var subtitle = getLineData(line2Config, responseRow);
		var details = getLineData(line3Config, responseRow);

		var keyValue = stripTokenChars(props.inputBox.Key);
		keyValue = getNestedPropertyValue(keyValue, responseRow);

		listData.push({
			title: title,
			subtitle: subtitle,
			details: details,
			key: keyValue
		});
	});

	return listData;
});

const getLineData = (line, currentDataRow) => {
	if (!line?.Text) {
		return;
	}

	var rowTextValue = '';
	var rowPreTextValue = '';

	var interopStrings = line.Text.split(' ');

	if (line.PreTextLocalized && line.PreText) {
		rowPreTextValue = translate(line.PreText, line.PreTextLocalized);
	}

	interopStrings.forEach(function (interopString) {
		if (!interopString) {
			return;
		}

		if (interopString.startsWith('#') && interopString.endsWith('#')) {
			interopString = stripTokenChars(interopString);

			var rowValue = getNestedPropertyValue(
				interopString,
				currentDataRow
			);

			if (rowValue || rowValue === 0) {
				switch (line.DataType) {
					case 'date':
						rowValue = $options.filters.formatDate(
							new Date(rowValue),
							'short',
							vm.proxy
						);
						break;
					case 'decimal':
						rowValue = $options.filters.formatNumber(
							rowValue,
							'decimal',
							vm.proxy
						);
						break;
				}

				// For now only apply formating to the interop values
				if (line.Formatting) {
					rowValue = createHtmlFormatedText(
						rowValue,
						line.Formatting
					);
				}

				rowTextValue = `${rowTextValue} ${rowValue}`;
			}
		} else {
			var staticText = translate(interopString, interopString);
			rowTextValue = `${rowTextValue} ${staticText}`;
		}

		rowTextValue = `${rowPreTextValue} ${rowTextValue}`;
	});

	return rowTextValue;
};

const translate = (defaultText, localizeText) => {
	return $options.filters.localizeText(defaultText, localizeText, vm.proxy);
};

const stripTokenChars = (interopString) => {
	if (!interopString) {
		return interopString;
	}

	if (interopString.startsWith('#') && interopString.endsWith('#')) {
		interopString = interopString.slice(1, -1);
	}

	return interopString;
};

const rowClick = (item) => {
	// should clicking actually do anything
	if (!props.inputBox.AllowUserRowClickEvent) {
		return;
	}

	// does clicking submit the form or not
	const submitAfterClickText = props.inputBox.CanRowSelectSubmitForm
		? ':SubmitForm'
		: '';

	emit(
		'inputUpdate',
		props.inputBox.Name + ':' + item.key + submitAfterClickText
	);
};
</script>
