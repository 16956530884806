<template>
	<div>
		<v-col class="text-center" cols="12">
			<h3>{{ translate(functionSettings.WorkflowName) }}</h3>
		</v-col>
		<v-form ref="scannerForm" @submit.prevent="onSubmit">
			<information-components-list
				ref="inputComponents"
				v-model="methodInputs"
				:user-prompts="functionSettings.ViewSettings.InputSection"
				@inputUpdate="onInputUpdate"
			/>

			<information-components-list
				ref="infoComponents"
				:user-prompts="functionSettings.ViewSettings.InfoSection"
			/>

			<action-components-list
				ref="ActionComponents"
				:action-items="functionSettings.ActionSettings"
				@actionWorkFlowSubmitTrigger="onActionWorkFlowSubmitTrigger"
			/>

			<input type="submit" style="position: absolute; left: -9999px" />
		</v-form>

		<modal-pop-over
			ref="modelPopOver"
			:model-view-setup="functionSettings.ActionSettings"
		/>
	</div>
</template>

<script>
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/userStore';
import { useScannerStore } from '@/stores/scannerFunctionStore';
import { mapState, mapActions } from 'pinia';
import InformationComponentsList from '@/components/ScanningFunctions/InformationComponentList.vue';
import ActionComponentsList from '@/components/ScanningFunctions/ActionComponentList.vue';
import scannerFunctionMixin from '@/mixins/scannerFunctionMixin';
import ModalPopOver from '@/components/ScanningFunctions/ModalPopOver.vue';
import { ShouldSkipStep } from '@/services/skipStep';
import { CallExternalApi } from '@/services/externalApiProcesser';

export default {
	name: 'BarCodeInputView',
	components: {
		'information-components-list': InformationComponentsList,
		'action-components-list': ActionComponentsList,
		'modal-pop-over': ModalPopOver
	},
	mixins: [scannerFunctionMixin],
	data: () => ({
		functionName: null,
		methodInputs: [],
		functionSettings: {
			ViewSettings: {},
			StepCriteria: {},
			ActionSettings: {},
			StepIteration: {}
		},
		functionOverideSettings: {},
		useButtonOverides: false
	}),
	computed: {
		...mapState(useScannerStore, ['getCurrentStep'])
	},
	watch: {
		getCurrentStep(newStep, oldStep) {
			//if our step has gone backwards and we're not the first step, then lets re-display the stage
			if (oldStep > newStep && newStep !== 1) {
				this.displayStage(newStep);
			}
		}
	},
	mounted() {
		this.functionName = this.$route.params.functionName;
		var step = parseInt(this.$route.params.step);
		this.removePayloads();
		this.setWorkflowStep({
			currentStep: step,
			currentWorkflowId: this.functionName
		});
		this.displayStage(step);
	},
	methods: {
		...mapState(useAuthStore, ['getUserId']),
		...mapState(useUserStore, [
			'getAllowedScannerFunctions',
			'getActiveSite',
			'getAllScannerActions'
		]),
		...mapState(useScannerStore, [
			'apiErrorMessage',
			'getLastApiResponse',
			'getPreviousResponses',
			'getAllApiResponses'
		]),
		...mapActions(useScannerStore, [
			'runApiGetRequest',
			'runApiPostRequest',
			'removePayloads',
			'removeUserInputAfterStep',
			'setWorkflowStep',
			'setIterationStepResponse'
		]),
		translate(key) {
			return this.$t(key);
		},
		onInputUpdate(value) {
			var pair = value.split(':');
			var item = this.methodInputs.find((x) => x.key == pair[0]);
			if (item) {
				item.value = pair[1];
			} else {
				this.methodInputs.push({ key: pair[0], value: pair[1] });
			}
		},
		displayStage(stepNumber) {
			this.methodInputs = [];
			this.$refs.scannerForm.reset();

			// -1 means start again
			if (stepNumber === -1) {
				//clear everything out
				this.removePayloads();
				this.setWorkflowStep({
					currentStep: 1,
					currentWorkflowId: this.functionName
				});
				stepNumber = 1;
			}

			// 9999 means back to the home menu
			if (stepNumber === 9999) {
				this.$router.push({ path: '/' });
			} else {
				this.loadInitialStepSettings(stepNumber);
			}
		},
		loadInitialStepSettings(stepNumber) {
			var allSettings = this.getAllowedScannerFunctions(
				1,
				this.functionName
			);

			this.functionSettings = null;
			this.functionSettings = allSettings.filter(
				(item) =>
					item.WorkFlowKey === this.functionName &&
					item.SequenceNumber === stepNumber
			)[0];

			if (
				this.functionSettings &&
				this.functionSettings.StepCriteria &&
				typeof this.functionSettings?.StepCriteria !== 'object'
			)
				this.functionSettings.StepCriteria = JSON.parse(
					this.functionSettings.StepCriteria
				);

			if (this.functionSettings?.StepCriteria?.optionalStep) {
				//get the data we need for the step skipping
				const apiResponse = this.getLastApiResponse();
				const allApiResponse = this.getAllApiResponses();

				if (
					ShouldSkipStep(
						this.functionSettings?.StepCriteria?.conditions,
						apiResponse,
						allApiResponse
					)
				) {
					this.setWorkflowStep({
						currentStep: stepNumber + 1,
						currentWorkflowId: this.functionName
					});
					this.loadInitialStepSettings(stepNumber + 1);
					return;
				}
			}

			if (
				this.functionSettings &&
				typeof this.functionSettings.ViewSettings !== 'object' &&
				this.functionSettings.ViewSettings !== null
			)
				this.functionSettings.ViewSettings = JSON.parse(
					this.functionSettings.ViewSettings
				);

			if (
				this.functionSettings &&
				typeof this.functionSettings?.SubmitSuccessSettings !==
					'object' &&
				this.functionSettings?.SubmitSuccessSettings !== null
			)
				this.functionSettings.SubmitSuccessSettings = JSON.parse(
					this.functionSettings.SubmitSuccessSettings
				);

			if (
				this.functionSettings &&
				typeof this.functionSettings?.ActionSettings !== 'object' &&
				this.functionSettings?.ActionSettings !== null
			) {
				this.functionSettings.ActionSettings = JSON.parse(
					this.functionSettings.ActionSettings
				);
			}
			if (
				this.functionSettings &&
				typeof this.functionSettings?.StepIteration !== 'object' &&
				this.functionSettings?.StepIteration !== null
			) {
				this.functionSettings.StepIteration = JSON.parse(
					this.functionSettings.StepIteration
				);
			}
			if (this.functionSettings?.ViewSettings?.InputSection == null) {
				this.onSubmit();
			}
		},
		onSubmit(e) {
			if (this.functionSettings?.ViewSettings?.SubmitOnEnterEvent) {
				e.preventDefault();
				return;
			}

			//check the has overids flag, if its not set then lets clear them out
			if (!this.useButtonOverides) {
				this.functionOverideSettings = {};
			}

			//roll over our inputs and add the valid looking ones
			var validFormInput = [];
			this.methodInputs.forEach((input) => {
				if (input.key && input.value && input.value !== 'null') {
					var newItem = { key: input.key, value: input.value };
					validFormInput.push(newItem);
				}
			});

			if (
				this.functionSettings.ExternalApiEndpointId !== null &&
				typeof this.functionSettings.ExternalApiEndpointId === 'number'
			) {
				var apiPayload = this.functionSettings.SubmitApiBody ?? '';
				apiPayload = this.updateAllPayloadTokens(apiPayload);

				var overrideUrl = '';
				if (this.functionSettings.SubmitApiUrl != '/') {
					overrideUrl = this.functionSettings.SubmitApiUrl;
					overrideUrl = this.updateAllPayloadTokens(overrideUrl);
				}

				var extraFilterConditions = null;
				if (this.functionSettings.ExtraRequestFilter != null) {
					extraFilterConditions = JSON.parse(
						this.updateAllPayloadTokens(
							this.functionSettings.ExtraRequestFilter
						)
					);
				}
				CallExternalApi(
					this.functionSettings.ExternalApiEndpointId,
					apiPayload,
					validFormInput,
					overrideUrl,
					extraFilterConditions
				).then(() => {
					if (
						this.functionSettings.SubmitSuccessSettings
							.SuccessMessage
					) {
						this.$toast.success(
							this.functionSettings.SubmitSuccessSettings
								.SuccessMessage,
							{
								timeout: parseInt(
									process.env.VUE_APP_TOASTTIMEOUT
								)
							}
						);
					}

					var nextStep = this.getNextStep();
					this.useButtonOverides = false;
					this.clearUserSelectedInputs();
					this.setWorkflowStep({
						currentStep: nextStep,
						currentWorkflowId: this.functionName
					});
					this.displayStage(nextStep);
				});

				return;
			}

			var baseUrlToUse = this.functionSettings.SubmitApiUrl;

			if (
				this.functionOverideSettings &&
				this.functionOverideSettings.SubmitApiUrl
			) {
				baseUrlToUse = this.functionOverideSettings.SubmitApiUrl;
			}

			var baseUrl = this.swapStandardTokens(baseUrlToUse);

			var stepIterationSettings = this.functionSettings.StepIteration;
			//do we have an itteration step going on here
			if (stepIterationSettings?.name) {
				//create a method we pass the current values to, along with the one we want out. That should mean we can re-factor stuff out
				if (
					this.doesIterationValueAlreadyExist(
						stepIterationSettings.collectionKey,
						validFormInput[0].value
					)
				) {
					this.$toast.error('Input Value already exists!', {
						timeout: parseInt(process.env.VUE_APP_TOASTTIMEOUT)
					});
					return;
				}

				//Need to use our input value to set the collection
				var urlToCall = this.swapStandardTokens(
					stepIterationSettings.getValidationRequestUrl
				);
				urlToCall = this.swapUserInputForTokens(urlToCall);
				urlToCall = this.swapPreviousUserInputs(urlToCall);

				//ok now we can run our get request
				this.runApiGetRequest({
					url: urlToCall,
					formInput: validFormInput
				})
					.then(() => {
						//if this works ok then we need to drop our new value into the store
						var itemToAddToCollection = this.swapUserInputForTokens(
							stepIterationSettings.inputKey
						);
						this.setIterationStepResponse({
							collectionName: stepIterationSettings.collectionKey,
							value: itemToAddToCollection
						});

						//ok now we go and check our counts, and if we have hit the max
						//lets move to the next step, or reset and carry on around the loop
						if (
							this.isStepIterationCountHit(
								stepIterationSettings.valueToIterate,
								stepIterationSettings.collectionKey
							)
						) {
							var nextStep = this.getNextStep();
							this.clearUserSelectedInputs();
							this.setWorkflowStep({
								currentStep: nextStep,
								currentWorkflowId: this.functionName
							});
							this.displayStage(nextStep);
						} else {
							var stepNumber = this.getCurrentStep;
							this.clearUserSelectedInputs();
							this.displayStage(stepNumber);
						}
					})
					.catch((e) => {
						this.$toast.error(e.response.data?.Title, {
							timeout: parseInt(process.env.VUE_APP_TOASTTIMEOUT)
						});
					});
			} else {
				var verbToUse =
					this.functionOverideSettings.SubmitVerb ??
					this.functionSettings.SubmitVerb;
				switch (verbToUse) {
					case 'GET':
						baseUrl = this.updateAllPayloadTokens(baseUrl);
						this.runGetRequest(baseUrl, validFormInput);
						break;
					case 'POST':
						var postPayload =
							this.functionOverideSettings.SubmitApiBody ??
							this.functionSettings.SubmitApiBody;
						postPayload = this.updateAllPayloadTokens(postPayload);
						this.runPostRequest(
							baseUrl,
							postPayload,
							validFormInput
						);
						break;
					default:
						console.error('Unknown Verb');
				}
			}
		},
		doesIterationValueAlreadyExist(keyToFind, valueToCheck) {
			var currentUserInputs = this.getPreviousResponses();
			var currentValue = this.getValueFromNestedArray(
				currentUserInputs,
				keyToFind
			);

			if (!currentValue) {
				return false;
			}

			//ok this might need to be a bit 'better' in future, but for now lets just see if our string exists
			if (currentValue.includes(valueToCheck)) {
				return true;
			}

			return false;
		},
		isStepIterationCountHit(valueToIterate, collectionKey) {
			var upperIteration = valueToIterate;
			upperIteration = this.swapPreviousUserInputs(upperIteration);
			upperIteration = this.swapAllApiResponses(upperIteration);

			//go and get the value of our collection key out
			var currentCollection = this.swapPreviousUserInputs(
				'#' + collectionKey + '#'
			);

			//get a count based on the fact we should be an array delimted by a pipe
			var collectionArray = currentCollection.split('|');
			var currentCount = Array.isArray(collectionArray)
				? collectionArray.length
				: 0;

			//now check to see if our count has hit the max yet
			return upperIteration <= currentCount;
		},
		runGetRequest(url, inputsFromForm) {
			this.runApiGetRequest({ url: url, formInput: inputsFromForm }).then(
				() => {
					if (
						this.functionSettings.SubmitSuccessSettings
							.SuccessMessage
					) {
						this.$toast.success(
							this.functionSettings.SubmitSuccessSettings
								.SuccessMessage,
							{
								timeout: parseInt(
									process.env.VUE_APP_TOASTTIMEOUT
								)
							}
						);
					}

					var nextStep = this.getNextStep();
					this.useButtonOverides = false;
					this.clearUserSelectedInputs();
					this.setWorkflowStep({
						currentStep: nextStep,
						currentWorkflowId: this.functionName
					});
					this.displayStage(nextStep);
				}
			);
		},
		runPostRequest(url, payload, inputsFromForm) {
			this.runApiPostRequest({
				url: url,
				body: payload,
				formInput: inputsFromForm
			}).then(() => {
				if (
					this.functionSettings.SubmitSuccessSettings.SuccessMessage
				) {
					this.$toast.success(
						this.functionSettings.SubmitSuccessSettings
							.SuccessMessage,
						{ timeout: parseInt(process.env.VUE_APP_TOASTTIMEOUT) }
					);
				}

				var nextStep = this.getNextStep();
				this.useButtonOverides = false;
				this.clearUserSelectedInputs();
				this.setWorkflowStep({
					currentStep: nextStep,
					currentWorkflowId: this.functionName
				});
				this.displayStage(nextStep);
			});
		},
		clearUserSelectedInputs() {
			if (
				!this.functionSettings.SubmitSuccessSettings
					?.RemoveInputsAfterStep &&
				!this.functionOverideSettings?.RemoveInputsAfterStep
			) {
				return;
			}

			//ok, we need to create an action on the store that we pass an array to thats going to remove what we dont need
			var stepToResetFrom = this.functionSettings.SubmitSuccessSettings
				?.RemoveInputsAfterStepNumber
				? this.functionSettings.SubmitSuccessSettings
						?.RemoveInputsAfterStepNumber
				: this.functionSettings.SubmitSuccessSettings.StepToMoveTo;

			var resetApiConfig = null;
			if (
				this.functionSettings?.SubmitSuccessSettings
					?.ResetApiResponses ||
				this.functionOverideSettings?.ResetApiResponses
			) {
				var stepToResetApiResponsesFrom = this.functionSettings
					.SubmitSuccessSettings?.ResetpApiResposesAfterStepNumber
					? this.functionSettings.SubmitSuccessSettings
							?.ResetpApiResposesAfterStepNumber
					: -1;

				resetApiConfig = {
					stepToResetFrom: stepToResetFrom,
					resetApiResponses: true,
					resetApiResponsesAfterStep: stepToResetApiResponsesFrom
				};
			} else {
				resetApiConfig = { stepToResetFrom: stepToResetFrom };
			}

			this.removeUserInputAfterStep(resetApiConfig);
		},

		swapUserInputForTokens(url) {
			this.methodInputs.forEach((element) => {
				var replaceString = '#' + element.key + '#';
				url = url.replace(replaceString, element.value);
			});
			return url;
		},

		swapStandardTokens(inputString) {
			inputString = inputString.replace('#siteId#', this.getActiveSite());
			inputString = inputString.replace('#userId#', this.getUserId());
			return inputString;
		},
		swapPreviousUserInputs(inputString) {
			var previousUserResponses = this.getPreviousResponses();

			if (!Array.isArray(previousUserResponses)) {
				return inputString;
			}

			previousUserResponses.forEach((responseStep) => {
				//ok we need to nest here again, as we have an array of arrays for each step
				responseStep.forEach((response) => {
					if (response) {
						var replaceString = '#' + response.key + '#';
						inputString = inputString.replace(
							replaceString,
							response.value
						);
					}
				});
			});

			return inputString;
		},
		swapAllApiResponses(inputString) {
			var allApiResponses = this.getAllApiResponses();

			if (allApiResponses === null || allApiResponses.length < 1) {
				return inputString;
			}

			allApiResponses.forEach(function (apiResponse) {
				if (typeof apiResponse === 'object' && apiResponse !== null) {
					Object.keys(apiResponse).forEach(function (key) {
						var tokenToFind = '#' + key + '#';
						inputString = inputString.replace(
							tokenToFind,
							apiResponse[key]
						);
					});
				}
			});
			return inputString;
		},

		//ok we are going to pull out any token values #xx# and replcae with a null
		//used to clean up any optional settings we dont want to send to the API
		swapTokenValuesForNull(payload) {
			if (payload.indexOf('#') > -1) {
				payload = payload.replace(/[[\]]/g, '');
				payload = payload.replace(/"#.*#"/g, 'null');
				payload = payload.replace(/#.*#/g, 'null');
			}

			return payload;
		},

		swapTokenValuesForUnknown(payload) {
			payload = payload.replace(/#.*#/g, 'UNKNOWN');
			return payload;
		},

		onActionWorkFlowSubmitTrigger(actionId, stepActionButtonId) {
			//lookup the action id from the list to see if we have any overiding to do
			var availableActions = this.getAllScannerActions();
			var actionSettings = availableActions.find(
				(o) => o.id === actionId
			);
			this.functionSettings.ViewSettings.SubmitOnEnterEvent = false;

			//if we dont have a payload just submit, otherwise call the procees to setup those
			if (actionSettings.payload) {
				this.setActionButtonSettings(
					actionSettings,
					stepActionButtonId
				);
			} else {
				this.useButtonOverides = false;
				this.onSubmit();
			}
		},
		setActionButtonSettings(actionSettings, stepActionButtonId) {
			var actionPayload = {};

			if (typeof actionSettings.payload !== 'object') {
				actionPayload = JSON.parse(actionSettings.payload);
			}

			//lets see what the action is, only one at the minute is to overide step settings - overideStepSettings": true
			if (actionPayload?.overideStepSettings) {
				// then we need to overide them, so pull the settings out of the current step action we have been sbumited from
				var stepActionSettings =
					this.functionSettings.ActionSettings.stepActions.find(
						(o) => o.id === stepActionButtonId
					);

				//Submit overide
				if (stepActionSettings.SubmitOveride) {
					//ok swap out the submit settings
					if (stepActionSettings.SubmitOveride.SubmitVerb) {
						this.functionOverideSettings.SubmitVerb =
							stepActionSettings.SubmitOveride.SubmitVerb;
					}

					if (stepActionSettings.SubmitOveride.SubmitApiUrl) {
						this.functionOverideSettings.SubmitApiUrl =
							stepActionSettings.SubmitOveride.SubmitApiUrl;
					}

					if (stepActionSettings.SubmitOveride.SubmitApiBody) {
						var bodyToEdit =
							stepActionSettings.SubmitOveride.SubmitApiBody;

						//replace single with double quotes
						bodyToEdit = bodyToEdit.replaceAll("'", '"');

						this.functionOverideSettings.SubmitApiBody = bodyToEdit;
					}
				}

				//ok now swap out the success overide
				if (stepActionSettings.SubmitSucessOveride) {
					if (stepActionSettings.SubmitSucessOveride.StepToMoveTo) {
						this.functionOverideSettings.StepToMoveTo =
							stepActionSettings.SubmitSucessOveride.StepToMoveTo;
					}
					if (
						stepActionSettings.SubmitSucessOveride
							.RemoveInputsAfterStep
					) {
						this.functionOverideSettings.RemoveInputsAfterStep =
							stepActionSettings.SubmitSucessOveride.RemoveInputsAfterStep;
					}
					if (
						stepActionSettings.SubmitSucessOveride
							.RemoveInputsAfterStepNumber
					) {
						this.functionOverideSettings.RemoveInputsAfterStepNumber =
							stepActionSettings.SubmitSucessOveride.RemoveInputsAfterStepNumber;
					}
					if (
						stepActionSettings.SubmitSucessOveride.ResetApiResponses
					) {
						this.functionOverideSettings.ResetApiResponses =
							stepActionSettings.SubmitSucessOveride.ResetApiResponses;
					}
				}
			}

			//now we can finally submit
			this.useButtonOverides = true;
			this.onSubmit();
		},
		getNextStep() {
			// we have an overide from the SubmitSuccessSettings for iteration, so see if we have hit that
			if (this.functionSettings.SubmitSuccessSettings.StepIterationKey) {
				// if this is the case we need to check the value of our key, and if we are in the range return back our StepIterationMoveTo value
				var upperIteration =
					this.functionSettings.SubmitSuccessSettings
						.StepIterationKey;

				var lastApiResponse = this.getLastApiResponse();
				upperIteration = this.swapTokenForValuesFromObject(
					upperIteration,
					'#',
					lastApiResponse
				);

				if (
					upperIteration ==
					this.functionSettings.SubmitSuccessSettings.StepIterationKey
				) {
					upperIteration =
						this.swapPreviousUserInputs(upperIteration);
					upperIteration = this.swapAllApiResponses(upperIteration);
				}

				if (upperIteration > 1) {
					return this.functionSettings.SubmitSuccessSettings
						.StepIterationMoveTo;
				} else {
					return this.functionSettings.SubmitSuccessSettings
						.StepToMoveTo;
				}
			}

			// we have an overide step from the actions
			if (this.functionOverideSettings.StepToMoveTo) {
				return this.functionOverideSettings.StepToMoveTo;
			}

			// default to the normal step
			return this.functionSettings.SubmitSuccessSettings.StepToMoveTo;
		},
		updateAllPayloadTokens(payload) {
			var lastApiResponse = this.getLastApiResponse();

			payload = this.swapStandardTokens(payload);
			payload = this.swapTokenForValuesFromObject(
				payload,
				'#',
				lastApiResponse
			);
			payload = this.swapPreviousUserInputs(payload);
			payload = this.swapUserInputForTokens(payload);
			payload = this.swapAllApiResponses(payload);
			payload = this.swapTokenValuesForNull(payload);

			return payload;
		}
	}
};
</script>
